const STORAGE_COOKIE_BANNER_KEY = "cookie-banner";

function initCookieBanner() {
  if (localStorage.getItem(STORAGE_COOKIE_BANNER_KEY)) {
    hideCookieBanner();
  } else {
    showCookieBanner();
  }
}

function showCookieBanner() {
  let banner = document.getElementById("cookie-banner");
  banner.style.display = "block";
}

function hideCookieBanner() {
  let banner = document.getElementById("cookie-banner");
  banner.style.display = "none";

  localStorage.setItem(STORAGE_COOKIE_BANNER_KEY, true);
}

window.onload = initCookieBanner;
window.hideCookieBanner = hideCookieBanner;
